import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  useBreakpointValue,
  useColorModeValue,
  useToast
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as Realm from 'realm-web'
import { useBaseFetch } from '../api/useBaseFetch'
import { useStore } from '../api/useStore'
import { PasswordField } from '../components/PasswordField'
import { Logo } from '../Logo'
import realm from '../realm/instance'

export const Login = () => {
  const loginState = useBaseFetch((x) => x.login)
  let navigate = useNavigate()
  const toast = useToast()
  const store = useStore()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: (values: any) => {
      const credentials = Realm.Credentials.emailPassword(values.email, values.password)
      realm.app
        .logIn(credentials)
        .then((l) => {
          toast({
            title: 'Başarılı.',
            description: 'Giriş yaptınız.',
            status: 'success',
            duration: 3000,
            isClosable: true
          })
          navigate('/checks')
        })
        .catch((t) => {
          toast({
            title: 'Hata.',
            description: 'Bilgileriniz Yanlış.',
            status: 'error',
            duration: 3000,
            isClosable: true
          })
        })
    }
  })

  return (
    <Flex bg={'brand.100'} h="100vh">
      <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
        <Stack spacing="8">
          <Stack spacing="6">
            <Logo />
            <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
              <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>
                Hesap Bilgilerinle Giriş Yap
              </Heading>
            </Stack>
          </Stack>
          <Box
            py={{ base: '0', sm: '8' }}
            px={{ base: '4', sm: '10' }}
            bg={'white'}
            boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
            borderRadius={{ base: 'none', sm: 'xl' }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing="6">
                <Stack spacing="5">
                  <FormControl>
                    <FormLabel htmlFor="email">Email Adresi</FormLabel>
                    <Input
                      id="email"
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                  </FormControl>
                  <PasswordField
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  />
                </Stack>

                {loginState && loginState?.data.status !== 200 && (
                  <Alert status="error" variant="solid">
                    <AlertIcon />
                    Geçersiz kullanıcı adı ve şifre kombinasyonu
                  </Alert>
                )}
                <HStack justify="space-between">
                  <Checkbox defaultChecked>Beni hatırla</Checkbox>
                  <Button variant="link" colorScheme="blue" size="sm">
                    <a href="/forgot-password">Şifremi unuttum?</a>
                  </Button>
                </HStack>
                <Stack spacing="6">
                  <Button colorScheme="blue" type="submit">
                    Giriş yap
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Container>
    </Flex>
  )
}
