import {
  Box,
  Divider,
  SimpleGrid,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import realm from '../realm/instance'

function Reports(props: any) {
  const [withdraw, setwithdraw] = useState(0)
  const [deposit, setdeposit] = useState(0)
  const [banks, setBanks] = useState<any>({})
  let customData = realm.app.currentUser?.customData
  let isPapara =
    customData?.role === 'Papara' ? { account: 'Papara' } : { account: { $ne: 'Papara' } }

  useEffect(() => {
    realm.withdraw?.find({ status: 'approved', ...isPapara }).then((data) => {
      let temp: any = {}
      data.forEach((c) => {
        let amount = parseInt(c.amount)
        let b = temp[c.account]
        if (b) {
          temp[c.account] = { ...temp[c.account], w: b.w + amount }
        } else {
          temp[c.account] = { d: 0, w: amount }
          temp[c.account] = { ...temp[c.account], w: amount }
        }
      })

      realm.deposit?.find({ status: 'approved', ...isPapara }).then((t) => {
        t.forEach((i) => {
          let amount = parseInt(i.amount)

          let b = temp[i.account]

          if (b) {
            temp[i.account] = { ...temp[i.account], d: b.d + amount }
          } else {
            temp[i.account] = { w: 0, d: amount }
            temp[i.account] = { ...temp[i.account], d: amount }
          }
        })
        setBanks({ ...temp })
      })
    })
  }, [])

  useEffect(() => {
    let d = 0
    let w = 0
    Object.keys(banks).map((c) => {
      d = d + banks[c].d
    })
    Object.keys(banks).map((c) => {
      w = w + banks[c].w
    })
    setdeposit(d)
    setwithdraw(w)
  }, [banks])

  return (
    <>
      <Box as="section" bg="white" rounded={'md'} p="5">
        <SimpleGrid columns={{ base: 1, md: 3 }} gap={{ base: '5', md: '6' }}>
          <Stat>
            <StatLabel color={'green.400'}>Toplam Yatırım</StatLabel>
            <StatNumber color={'green.400'}>{deposit} TL</StatNumber>
          </Stat>
          <Stat>
            <StatLabel color={'red.400'}>Toplam Çekim</StatLabel>
            <StatNumber color={'red.400'}>{withdraw} TL</StatNumber>
          </Stat>
        </SimpleGrid>
        <Divider my="5" />
        <Text mb="4" fontWeight={'bold'} fontSize="xl">
          Bankalar
        </Text>
        <SimpleGrid columns={{ base: 1, md: 4 }} gap={{ base: '5', md: '6' }}>
          {Object.keys(banks).map((bank) => (
            <Stat border="1px" rounded={'md'} p="4">
              <StatLabel fontSize={'md'}>{bank}</StatLabel>
              <StatNumber>{banks[bank].d - banks[bank].w} TL</StatNumber>
              <StatHelpText fontSize={'md'}>
                <Text color={'green'} fontWeight="bold">
                  Gelen Havale:
                </Text>
                <b> {banks[bank].d} TL</b>
                <Box />
                <Text color={'red'} fontWeight="bold">
                  Giden Havale:
                </Text>
                <b> {banks[bank].w} TL</b>
              </StatHelpText>
            </Stat>
          ))}
        </SimpleGrid>
      </Box>
    </>
  )
}

export default Reports
