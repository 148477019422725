import {
  Avatar,
  Box,
  BoxProps,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { IconType } from 'react-icons'
import {
  FiCheckCircle,
  FiChevronDown,
  FiCpu,
  FiLoader,
  FiMenu,
  FiSettings,
  FiXCircle
} from 'react-icons/fi'
import { Link as GoLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useStore } from './api/useStore'
import realm from './realm/instance'

interface LinkItemProps {
  name: string
  url: string
  icon: IconType
}
const LinkItems: Array<LinkItemProps> = [
  { name: 'Onay Bekleyenler', icon: FiLoader, url: '/checks' },
  { name: 'Onaylananlar', icon: FiCheckCircle, url: '/approved' },
  { name: 'İptal Edilenler', icon: FiXCircle, url: '/reject' },
  { name: 'Banka Hesapları', icon: FiSettings, url: '/accounts' },
  { name: 'Raporlar', icon: FiCpu, url: '/reports' }
]

export default function App() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  let navigate = useNavigate()

  useEffect(() => {
    let user = realm.app.currentUser
    if (user === null) {
      navigate('/login')
    }
  }, [])

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <Outlet />
      </Box>
    </Box>
  )
}

interface SidebarProps extends BoxProps {
  onClose: () => void
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  let path = useLocation().pathname

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="md" fontFamily="monospace" fontWeight="bold">
          betonline.finance
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <GoLink to={link.url} style={{ textDecoration: 'none' }}>
          <Flex
            alignItems={'center'}
            p="4"
            mx="4"
            borderRadius="lg"
            role="group"
            cursor="pointer"
            bg={link.url === path ? 'blue.400' : 'transparent'}
            color={link.url === path ? 'white' : 'black'}
            _hover={{
              bg: 'blue.400',
              color: 'white'
            }}
            {...rest}
          >
            {link.icon && (
              <Icon
                mr="4"
                fontSize="16"
                _groupHover={{
                  color: 'white'
                }}
                as={link.icon}
              />
            )}
            {link.name}
          </Flex>
        </GoLink>
      ))}
    </Box>
  )
}

interface MobileProps extends FlexProps {
  onOpen: () => void
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  let navigate = useNavigate()
  let store = useStore()
  useEffect(() => {
    realm.bankers?.find({ _id: realm.app.currentUser?.customData.bankerId }).then((b) => {
      store.setState({ state: 'banker', data: b[0] })
    })
  }, [])

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        Logo
      </Text>

      <HStack spacing={{ base: '0', md: '6' }}>
        {/*         <IconButton size="lg" variant="ghost" aria-label="open menu" icon={<FiBell />} />
         */}{' '}
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar
                  size={'sm'}
                  src={
                    'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                  }
                />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">Test Hesap</Text>
                  <Text fontSize="xs" color="gray.600">
                    Admin
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuDivider />
              <MenuItem
                onClick={() => {
                  realm.app.currentUser?.logOut()
                  navigate('/login')
                }}
              >
                Çıkış Yap
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  )
}
