import { Outlet } from 'react-router-dom'

function EmptyLayout(props: any) {
  return (
    <>
      <Outlet />
    </>
  )
}

export default EmptyLayout
