import { Card, CardBody, Divider, Heading, Image, Stack, Text } from '@chakra-ui/react'

export default function TestCard({ text, onClick, type }: any) {
  return (
    <Card
      maxW="sm"
      bg="gray.200"
      border="1px solid"
      borderColor={'gray.300'}
      onClick={onClick}
      _hover={{
        cursor: 'pointer',
        shadow: 'lg'
      }}
    >
      <CardBody>
        <Image
          src={
            text.includes('Papara')
              ? 'https://cdn.nagcdn.com/ngamingbase/core/assets/images/payment-icons/42.png'
              : 'https://cdn.nagcdn.com/ngamingbase/core/assets/images/payment-icons/97.png'
          }
          alt="Green double couch with wooden legs"
          borderRadius="lg"
        />
        <Stack mt="6" spacing="1">
          <Heading size="md">{text}</Heading>
          <Text pt="4">Limit</Text>
          <Text color="blue.600">Min: 100.00TRY</Text>
          <Text color="blue.600">Max: 250,000.00TRY</Text>
        </Stack>
      </CardBody>
      <Divider />
    </Card>
  )
}
