import { Button, Divider, Flex, Grid, HStack, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import realm from '../realm/instance'

export default function Approved() {
  const [type, setType] = useState<any>(0)
  const [accounts, setAccounts] = useState<any>(undefined)
  const [tenants, setTenants] = useState<any>(undefined)

  let customData = realm.app.currentUser?.customData
  let isPapara =
    customData?.role === 'Papara' ? { account: 'Papara' } : { account: { $ne: 'Papara' } }

  let aggregate = [
    { $match: { status: 'approved', ...isPapara } },
    {
      $lookup: {
        from: 'tenants',
        localField: 'tenantId',
        foreignField: '_id',
        as: 'tenant'
      }
    },
    { $sort: { updatedAt: -1 } }
  ]

  let getAccounts = () => {
    if (type === 0) {
      realm.deposit?.aggregate(aggregate).then((data: any) => {
        setAccounts(data)
      })
    }
    if (type === 1) {
      realm.withdraw?.aggregate(aggregate).then((data: any) => {
        setAccounts(data)
      })
    }
  }

  useEffect(() => {
    realm.deposit?.aggregate(aggregate).then((data: any) => {
      setAccounts(data)
    })
    realm.tenants?.find({}).then((data: any) => {
      setTenants(data)
    })
  }, [])

  useEffect(() => {
    getAccounts()
  }, [type])

  return (
    <>
      <Flex mb="4" justifyContent={'space-between'}>
        <HStack spacing={4}>
          <Button
            colorScheme="yellow"
            variant="outline"
            isActive={type === 0}
            onClick={() => {
              setType(0)
            }}
          >
            Yatırımlar
          </Button>
          <Button
            colorScheme="green"
            variant="outline"
            isActive={type === 1}
            onClick={() => {
              setType(1)
            }}
          >
            Çekimler
          </Button>
        </HStack>
      </Flex>

      <Flex bg="white" flexDir="column">
        <Grid
          gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr '}
          justifyItems="space-between"
          w={'100%'}
          px="5"
          py={2}
          alignItems={'center'}
        >
          <Text>Site</Text>
          <Text>Onay Tarihi</Text>
          <Text>İsim Soyisim</Text>
          <Text>Banka</Text>
          <Text>Miktar</Text>
        </Grid>
        <Divider />
        {accounts &&
          accounts?.map((item: any) => {
            return (
              <Grid
                gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr '}
                justifyItems="space-between"
                w={'100%'}
                p="5"
                alignItems={'center'}
              >
                <Text>{item.tenant[0].name}</Text>
                <Text>
                  {
                    dayjs(item.updatedAt).format('DD/MM/YYYY HH:mm') // '25/01/2019'
                  }
                </Text>
                <Text fontWeight={'bold'}>{item.name}</Text>
                <Text>{item.account}</Text>
                <Text>{item.amount}</Text>
              </Grid>
            )
          })}
      </Flex>
    </>
  )
}
