import { HStack } from '@chakra-ui/react'
import { useState } from 'react'
import DepositModal from '../components/DepositModal'
import TestCard from '../components/TestCard'
import WithdrawModal from '../components/WithdrawModal'

export default function TestPage() {
  const [openModal, setOpenModal] = useState(false)
  const [sendType, SetSendType] = useState(0)

  return (
    <HStack spacing={'10'} m="10">
      <TestCard
        onClick={() => {
          setOpenModal(true)
          SetSendType(0)
        }}
        text="Havale Çekim"
      />
      <TestCard
        onClick={() => {
          setOpenModal(true)
          SetSendType(1)
        }}
        text="Havale Yatırım"
      />
      <TestCard
        onClick={() => {
          setOpenModal(true)
          SetSendType(2)
        }}
        text="Papara Çekim"
      />
      <TestCard
        onClick={() => {
          setOpenModal(true)
          SetSendType(3)
        }}
        text="Papara Yatırım"
      />
      {openModal && (sendType === 1 || sendType === 3) && (
        <DepositModal
          type={sendType}
          onClose={() => {
            setOpenModal(false)
          }}
        />
      )}

      {openModal && (sendType === 0 || sendType === 2) && (
        <WithdrawModal
          type={sendType}
          onClose={() => {
            setOpenModal(false)
          }}
        />
      )}
    </HStack>
  )
}
