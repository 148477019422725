import create from 'zustand'

interface FetchState {
  banker?: any
  setState: (data: any) => void
}

export const useStore = create<FetchState>((set) => ({
  banker: undefined,
  setState: async (data) => {
    set({ [data.state]: data.data })
  }
}))
