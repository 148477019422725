import { App } from 'realm-web'
import { APP_ID } from './constants'

const app = new App({ id: APP_ID })
const mongo = app.currentUser && app.currentUser.mongoClient('mongodb-atlas')
const accounts = mongo && mongo.db('master').collection('accounts')
const tenants = mongo && mongo.db('master').collection('tenants')
const withdraw = mongo && mongo.db('master').collection('withdraw')
const deposit = mongo && mongo.db('master').collection('deposit')
const bankers = mongo && mongo.db('master').collection('bankers')

const realm = {
  app: app,
  accounts,
  tenants,
  bankers,
  withdraw,
  deposit
}

export default realm
