import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Select,
  Stack,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useStore } from '../../../api/useStore'
import { Type } from '../../../realm/constants'
import realm from '../../../realm/instance'

function AddAccounts(props: any) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const store = useStore()

  const formik = useFormik({
    initialValues: {
      type: '',
      name: '',
      iban: '',
      no: '',
      minAmount: '',
      maxAmount: ''
    },
    onSubmit: (values: any) => {
      let doc = {
        bankerId: store.banker._id,
        type: '',
        information: {},
        isWithdraw: false,
        isActive: false,
        minAmount: parseInt(values.minAmount),
        maxAmount: parseInt(values.maxAmount)
      }

      if (props.type === 0) {
        doc.type = values.type
        doc.information = {
          no: values.no,
          iban: values.iban,
          name: values.name
        }
        doc.isWithdraw = true
      }
      if (props.type === 1) {
        doc.type = values.type
        doc.information = {
          no: values.no,
          iban: values.iban,
          name: values.name
        }
        doc.isWithdraw = false
      }
      if (props.type === 2) {
        doc.type = 'Papara'
        doc.information = {
          no: values.no,
          name: values.name
        }
        doc.isWithdraw = true
      }
      if (props.type === 3) {
        doc.type = 'Papara'
        doc.information = {
          no: values.no,
          name: values.name
        }
        doc.isWithdraw = false
      }

      realm.accounts
        ?.insertOne({ ...doc, createdAt: new Date() })
        .then(() => {
          toast({
            title: 'Başarılı.',
            description: 'Oluşturuldu.',
            status: 'success',
            duration: 3000,
            isClosable: true
          })

          formik.resetForm()
          onClose()
          props.onClose()
        })
        .catch(() => {
          toast({
            title: 'Hata.',
            description: 'Bir Hata Oluştu.',
            status: 'error',
            duration: 3000,
            isClosable: true
          })
        })
    }
  })
  return (
    <>
      <Button colorScheme={'blue'} onClick={onOpen}>
        {Type[props.type]} Hesabı Ekle
      </Button>

      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={() => {
            formik.resetForm()
            onClose()
            props.onClose()
          }}
          size="2xl"
        >
          <ModalOverlay />
          <form onSubmit={formik.handleSubmit}>
            <ModalContent>
              <ModalHeader> {Type[props.type]} Hesabı Ekle</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {props.type === 2 || props.type == 3 ? (
                  <Stack spacing="5">
                    <FormControl>
                      <FormLabel>Hesap Sahibi</FormLabel>
                      <Input
                        id="name"
                        name="name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Papara No</FormLabel>
                      <Input
                        id="no"
                        name="no"
                        onChange={formik.handleChange}
                        value={formik.values.no}
                      />
                    </FormControl>
                    <HStack spacing={'4'}>
                      <FormControl>
                        <FormLabel>Minimum Miktar</FormLabel>
                        <NumberInput min={100} max={99999999999}>
                          <NumberInputField
                            id="minAmount"
                            name="minAmount"
                            onChange={formik.handleChange}
                            value={formik.values.minAmount}
                          />
                        </NumberInput>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Maksimum Miktar</FormLabel>
                        <NumberInput min={100} max={99999999999}>
                          <NumberInputField
                            id="maxAmount"
                            name="maxAmount"
                            onChange={formik.handleChange}
                            value={formik.values.maxAmount}
                          />
                        </NumberInput>
                      </FormControl>
                    </HStack>
                  </Stack>
                ) : (
                  <Stack spacing="5">
                    <FormControl>
                      <FormLabel>Banka</FormLabel>
                      <Select
                        placeholder="Banka Seçiniz"
                        id="type"
                        name="type"
                        onChange={formik.handleChange}
                        value={formik.values.type}
                      >
                        <option value="Akbank">Akbank</option>
                        <option value="KuveytTürk">KuveytTürk</option>
                        <option value="Denizbank">Denizbank</option>
                        <option value="Halkbank">Halkbank</option>
                        <option value="VakıfBank">VakıfBank</option>
                        <option value="ZiraatBankası">ZiraatBankası</option>
                        <option value="Anadolubank">Anadolubank</option>
                        <option value="Fibabanka">Fibabanka</option>
                        <option value="Şekerbank">Şekerbank</option>
                        <option value="TurkishBank">TurkishBank</option>
                        <option value="TürkEkonomiBankası">TürkEkonomiBankası</option>
                        <option value="YapıKredi">YapıKredi</option>
                        <option value="TürkiyeİşBankası">TürkiyeİşBankası</option>
                        <option value="AlternatifBank">AlternatifBank</option>
                        <option value="BurganBank">BurganBank</option>
                        <option value="Citibank">Citibank</option>
                        <option value="DenizBank">DenizBank</option>
                        <option value="DeutscheBank">DeutscheBank</option>
                        <option value="GarantiBBVA">GarantiBBVA</option>
                        <option value="HSBC">HSBC</option>
                        <option value="ICBCTurkeyBank">ICBCTurkeyBank</option>
                        <option value="INGBank">INGBank</option>
                        <option value="Odeabank">Odeabank</option>
                        <option value="QNBFinansbank">QNBFinansbank</option>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Hesap Sahibi</FormLabel>
                      <Input
                        id="name"
                        name="name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Hesap No</FormLabel>
                      <Input
                        id="no"
                        name="no"
                        onChange={formik.handleChange}
                        value={formik.values.no}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>IBAN</FormLabel>
                      <Input
                        id="iban"
                        name="iban"
                        onChange={formik.handleChange}
                        value={formik.values.iban}
                      />
                    </FormControl>
                    <HStack spacing={'4'}>
                      <FormControl>
                        <FormLabel>Minimum Miktar</FormLabel>
                        <NumberInput>
                          <NumberInputField
                            id="minAmount"
                            name="minAmount"
                            onChange={formik.handleChange}
                            value={formik.values.minAmount}
                          />
                        </NumberInput>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Maksimum Miktar</FormLabel>
                        <NumberInput>
                          <NumberInputField
                            id="maxAmount"
                            name="maxAmount"
                            onChange={formik.handleChange}
                            value={formik.values.maxAmount}
                          />
                        </NumberInput>
                      </FormControl>
                    </HStack>
                  </Stack>
                )}
              </ModalBody>

              <ModalFooter>
                <Button variant="solid" colorScheme={'green'} type="submit">
                  Hesap Ekle
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </>
  )
}

export default AddAccounts
