import { Button, Divider, Flex, Grid, HStack, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import realm from '../realm/instance'

export default function Checks() {
  const [type, setType] = useState<any>(0)
  const [accounts, setAccounts] = useState<any>(undefined)
  let customData = realm.app.currentUser?.customData

  let isPapara =
    customData?.role === 'Papara' ? { account: 'Papara' } : { account: { $ne: 'Papara' } }

  let aggregate = [
    { $match: { status: 'created', ...isPapara } },
    {
      $lookup: {
        from: 'tenants',
        localField: 'tenantId',
        foreignField: '_id',
        as: 'tenant'
      }
    },
    { $sort: { createdAt: -1 } }
  ]

  let getAccounts = () => {
    if (type === 0) {
      realm.deposit?.aggregate(aggregate).then((data: any) => {
        setAccounts(data)
      })
    }
    if (type === 1) {
      realm.withdraw?.aggregate(aggregate).then((data: any) => {
        setAccounts(data)
      })
    }
  }

  let approved = (data: any) => {
    if (type === 0) {
      realm.deposit
        ?.updateOne({ _id: data._id }, { $set: { status: 'approved', updatedAt: new Date() } })
        .then((data: any) => {
          realm.bankers?.findOne({ _id: customData?.bankerId }).then((y) => {
            realm.bankers
              ?.updateOne(
                { _id: customData?.bankerId },
                { $set: { depositCount: y.depositCount + data.amount } }
              )
              .then(() => {
                getAccounts()
              })
          })
        })
    }
    if (type === 1) {
      realm.withdraw
        ?.updateOne({ _id: data._id }, { $set: { status: 'approved', updatedAt: new Date() } })
        .then((data: any) => {
          realm.bankers?.findOne({ _id: customData?.bankerId }).then((y) => {
            realm.bankers
              ?.updateOne(
                { _id: customData?.bankerId },
                { $set: { withdrawCount: y.withdrawCount + data.amount } }
              )
              .then(() => {
                getAccounts()
              })
          })
        })
    }
  }

  let reject = (id: string) => {
    if (type === 0) {
      realm.deposit
        ?.updateOne({ _id: id }, { $set: { status: 'rejected', updatedAt: new Date() } })
        .then((data: any) => {
          getAccounts()
        })
    }
    if (type === 1) {
      realm.withdraw
        ?.updateOne({ _id: id }, { $set: { status: 'rejected', updatedAt: new Date() } }, {})
        .then((data: any) => {
          getAccounts()
        })
    }
  }

  useEffect(() => {
    realm.deposit?.aggregate(aggregate).then((data: any) => {
      setAccounts(data)
    })
  }, [])

  useEffect(() => {
    getAccounts()
  }, [type])

  return (
    <>
      <Flex mb="4" justifyContent={'space-between'}>
        <HStack spacing={4}>
          <Button
            colorScheme="yellow"
            variant="outline"
            isActive={type === 0}
            onClick={() => {
              setType(0)
            }}
          >
            Yatırımlar
          </Button>
          <Button
            colorScheme="green"
            variant="outline"
            isActive={type === 1}
            onClick={() => {
              setType(1)
            }}
          >
            Çekimler
          </Button>
        </HStack>
      </Flex>

      <Flex bg="white" flexDir="column">
        <Grid
          gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr 100px 100px'}
          justifyItems="space-between"
          w={'100%'}
          px="5"
          py={2}
          alignItems={'center'}
        >
          <Text>Site</Text>
          <Text>Tarih</Text>
          <Text>İsim Soyisim</Text>
          <Text>Banka</Text>
          <Text>Miktar</Text>
          <Text textAlign={'center'}>Onayla</Text>
          <Text textAlign={'center'}>İptal Et</Text>
        </Grid>
        <Divider />
        {accounts &&
          accounts?.map((item: any) => {
            return (
              <Grid
                gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr 120px 100px'}
                justifyItems="space-between"
                w={'100%'}
                p="5"
                alignItems={'center'}
              >
                <Text>{item.tenant[0].name}</Text>
                <Text>
                  {
                    dayjs(item.createdAt).format('DD/MM/YYYY HH:mm') // '25/01/2019'
                  }
                </Text>
                <Text fontWeight={'bold'}>{item.name}</Text>
                <Text>{item.account}</Text>
                <Text>{item.amount}</Text>

                <Button
                  colorScheme={'green'}
                  size="sm"
                  w="100px"
                  onClick={() => {
                    approved(item)
                  }}
                >
                  Onayla
                </Button>
                <Button
                  colorScheme={'red'}
                  size="sm"
                  w="100px"
                  onClick={() => {
                    reject(item._id)
                  }}
                >
                  İptal Et
                </Button>
              </Grid>
            )
          })}
      </Flex>
    </>
  )
}
