import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Select
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useState } from 'react'

function WithdrawModal({ onClose, type }: any) {
  let isPapara = type === 2 ? true : false

  const [isLoading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues: {
      type: isPapara ? 'Papara' : '',
      name: '',
      iban: '',
      no: '',
      amount: ''
    },
    onSubmit: (values: any) => {
      fetch('https://api.betonline.finance/withdraw', {
        method: 'POST',
        headers: {
          'api-key': 'BLezhaswaj57z2DsukYx0CAkPJFxKrZfXEqoToz13OzrYb1YreXv7zyHktwXE0LR'
        },
        body: JSON.stringify({
          name: values.name,
          account: values.type,
          no: values.no,
          iban: values.iban,
          amount: values.amount
        })
      })
        .then((data) => data.json())
        .then((data) => {
          setLoading(false)
          onClose()
        })
    }
  })
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Çekim</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <>
            {!isPapara && (
              <FormControl>
                <FormLabel>Banka</FormLabel>
                <Select
                  placeholder="Banka Seçiniz"
                  id="type"
                  name="type"
                  onChange={formik.handleChange}
                  value={formik.values.type}
                >
                  <option value="Akbank">Akbank</option>
                  <option value="KuveytTürk">KuveytTürk</option>
                  <option value="Denizbank">Denizbank</option>
                  <option value="Halkbank">Halkbank</option>
                  <option value="VakıfBank">VakıfBank</option>
                  <option value="ZiraatBankası">ZiraatBankası</option>
                  <option value="Anadolubank">Anadolubank</option>
                  <option value="Fibabanka">Fibabanka</option>
                  <option value="Şekerbank">Şekerbank</option>
                  <option value="TurkishBank">TurkishBank</option>
                  <option value="TürkEkonomiBankası">TürkEkonomiBankası</option>
                  <option value="YapıKredi">YapıKredi</option>
                  <option value="TürkiyeİşBankası">TürkiyeİşBankası</option>
                  <option value="AlternatifBank">AlternatifBank</option>
                  <option value="BurganBank">BurganBank</option>
                  <option value="Citibank">Citibank</option>
                  <option value="DenizBank">DenizBank</option>
                  <option value="DeutscheBank">DeutscheBank</option>
                  <option value="GarantiBBVA">GarantiBBVA</option>
                  <option value="HSBC">HSBC</option>
                  <option value="ICBCTurkeyBank">ICBCTurkeyBank</option>
                  <option value="INGBank">INGBank</option>
                  <option value="Odeabank">Odeabank</option>
                  <option value="QNBFinansbank">QNBFinansbank</option>
                </Select>
              </FormControl>
            )}
            {isPapara && (
              <FormControl mt="4">
                <FormLabel>Hesap No</FormLabel>
                <Input id="no" name="no" onChange={formik.handleChange} value={formik.values.no} />
              </FormControl>
            )}
            <FormControl mt="4">
              <FormLabel>Hesap Sahibi</FormLabel>
              <Input
                id="name"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </FormControl>
            {!isPapara && (
              <FormControl mt="4">
                <FormLabel>IBAN</FormLabel>
                <Input
                  id="iban"
                  name="iban"
                  onChange={formik.handleChange}
                  value={formik.values.iban}
                />
              </FormControl>
            )}

            <FormControl mt="4">
              <FormLabel>Miktar (Min Tutar: 500TL)</FormLabel>
              <NumberInput>
                <NumberInputField
                  id="amount"
                  name="amount"
                  onChange={formik.handleChange}
                  value={formik.values.amount}
                />
              </NumberInput>
            </FormControl>
          </>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="green"
            isLoading={isLoading}
            onClick={() => {
              setLoading(true)
              formik.handleSubmit()
            }}
          >
            Onayla
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default WithdrawModal
