export enum Endpoints {
  login = 'login',
  register = 'register',
  getRegister = 'getRegister',
  getClient = 'getClient',
  getAddress = 'getAddress',
  sessions = 'sessions',
  forgotPassword = 'forgotPassword',
  changePassword = 'changePassword'
}

export interface IEnpoints {
  url: string
  method: string
}

const endpoints: { [key: string]: IEnpoints } = {
  login: { url: 'oauth/v2/token', method: 'GET' },
  register: { url: 'api/v1/user/registration/form', method: 'POST' },
  getRegister: { url: 'api/v1/user/registration/form', method: 'GET' },
  getClient: { url: 'api/v1/get/client', method: 'GET' },
  getAddress: { url: 'api/getAppByHostname', method: 'GET' },
  sessions: { url: 'api/get/session', method: 'GET' },
  forgotPassword: { url: 'api/v1/auth/resetting/send-email', method: 'POST' },
  changePassword: { url: 'api/change-password', method: 'POST' }
}

export default endpoints
