import { ChakraProvider } from '@chakra-ui/react'
import * as ReactDOM from 'react-dom/client'

import dayjs from 'dayjs'
import 'dayjs/locale/tr' // import locale
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import App from './App'
import ErrorPage from './error-page'
import EmptyLayout from './layouts/EmptyLayout'
import Accounts from './pages/Accounts/Accounts'
import Approved from './pages/Approved'
import Checks from './pages/Checks'
import Homepage from './pages/Homepage/Homepage'
import { Login } from './pages/Login'
import Rejected from './pages/Rejected'
import Reports from './pages/Reports'
import TestPage from './pages/TestPage'
import theme from './theme'

dayjs.locale('tr') // use locale

const container = document.getElementById('root')
if (!container) throw new Error('Failed to find the root element')
const root = ReactDOM.createRoot(container)

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Homepage />
      },
      {
        path: '/checks',
        element: <Checks />
      },
      {
        path: '/approved',
        element: <Approved />
      },
      {
        path: '/reject',
        element: <Rejected />
      },
      {
        path: '/accounts',
        element: <Accounts />
      },
      {
        path: '/reports',
        element: <Reports />
      }
    ]
  },
  {
    path: '/',
    element: <EmptyLayout />,
    children: [
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/test',
        element: <TestPage />
      },
      {
        path: '/havale',
        element: <Homepage />
      }
    ]
  }
])

root.render(
  <ChakraProvider theme={theme}>
    <RouterProvider router={router} />
  </ChakraProvider>
)
