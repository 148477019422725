import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Select,
  Text,
  VStack
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useState } from 'react'

function DepositModal({ onClose, type }: any) {
  const [account, setAccount] = useState<any>(undefined)
  const [isDisable, setDisable] = useState<any>(false)
  let isPapara = type === 3 ? true : false
  const formik = useFormik({
    initialValues: {
      type: isPapara ? 'Papara' : '',
      name: '',
      no: '',
      amount: ''
    },
    onSubmit: (values: any) => {
      fetch('https://api.betonline.finance/deposit', {
        method: 'POST',
        headers: {
          'api-key': 'BLezhaswaj57z2DsukYx0CAkPJFxKrZfXEqoToz13OzrYb1YreXv7zyHktwXE0LR'
        },
        body: JSON.stringify({
          name: values.name,
          account: values.type,
          no: values.no,
          amount: values.amount
        })
      })
        .then((data) => data.json())
        .then((data) => {
          setAccount(data.account)
          setDisable(false)
        })
    }
  })
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <form>
        <ModalContent>
          <ModalHeader>Yatırım</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!account && (
              <>
                {!isPapara && (
                  <FormControl>
                    <FormLabel>Banka</FormLabel>
                    <Select
                      placeholder="Banka Seçiniz"
                      id="type"
                      name="type"
                      onChange={formik.handleChange}
                      value={formik.values.type}
                    >
                      <option value="Akbank">Akbank</option>
                      <option value="KuveytTürk">KuveytTürk</option>
                      <option value="Denizbank">Denizbank</option>
                      <option value="Halkbank">Halkbank</option>
                      <option value="VakıfBank">VakıfBank</option>
                      <option value="ZiraatBankası">ZiraatBankası</option>
                      <option value="Anadolubank">Anadolubank</option>
                      <option value="Fibabanka">Fibabanka</option>
                      <option value="Şekerbank">Şekerbank</option>
                      <option value="TurkishBank">TurkishBank</option>
                      <option value="TürkEkonomiBankası">TürkEkonomiBankası</option>
                      <option value="YapıKredi">YapıKredi</option>
                      <option value="TürkiyeİşBankası">TürkiyeİşBankası</option>
                      <option value="AlternatifBank">AlternatifBank</option>
                      <option value="BurganBank">BurganBank</option>
                      <option value="Citibank">Citibank</option>
                      <option value="DenizBank">DenizBank</option>
                      <option value="DeutscheBank">DeutscheBank</option>
                      <option value="GarantiBBVA">GarantiBBVA</option>
                      <option value="HSBC">HSBC</option>
                      <option value="ICBCTurkeyBank">ICBCTurkeyBank</option>
                      <option value="INGBank">INGBank</option>
                      <option value="Odeabank">Odeabank</option>
                      <option value="QNBFinansbank">QNBFinansbank</option>
                    </Select>
                  </FormControl>
                )}
                {isPapara && (
                  <FormControl mt="4">
                    <FormLabel>Hesap No</FormLabel>
                    <Input
                      id="no"
                      name="no"
                      onChange={formik.handleChange}
                      value={formik.values.no}
                    />
                  </FormControl>
                )}
                <FormControl mt="4">
                  <FormLabel>Hesap Sahibi</FormLabel>
                  <Input
                    id="name"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                </FormControl>

                <FormControl mt="4">
                  <FormLabel>Miktar (Min Tutar: 500TL)</FormLabel>
                  <NumberInput>
                    <NumberInputField
                      id="amount"
                      name="amount"
                      onChange={formik.handleChange}
                      value={formik.values.amount}
                    />
                  </NumberInput>
                </FormControl>
              </>
            )}
            {account && (
              <VStack alignItems={'left'}>
                <Text>
                  <b>Adı Soyadı: </b> {account.name}
                </Text>
                <Text>
                  <b>Hesap No:</b> {account.no}
                </Text>
                <Text>
                  <b>IBAN:</b> {account.iban}
                </Text>
                <Text>Lütfen açıklamayı boş bırakınız</Text>
              </VStack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="green"
              isLoading={isDisable}
              onClick={() => {
                if (!account) {
                  formik.handleSubmit()
                  setDisable(true)
                } else {
                  onClose()
                }
              }}
            >
              Onayla
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}

export default DepositModal
function setAccount(account: any) {
  throw new Error('Function not implemented.')
}
