import { Button, Divider, Flex, Grid, HStack, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useStore } from '../../api/useStore'
import realm from '../../realm/instance'
import AddAccounts from './bone/AddAccounts'
import EditAccounts from './bone/EditAccounts'

export default function Accounts() {
  let customData = realm.app.currentUser?.customData
  let isPapara = customData?.role === 'Papara' ? { type: 'Papara' } : { type: { $ne: 'Papara' } }

  const [type, setType] = useState<any>(0)
  const [accounts, setAccounts] = useState<any>(undefined)
  const store = useStore()

  let getAccounts = () => {
    let query = {}
    if (type === 0) {
      query = { isWithdraw: true, ...isPapara }
    }
    if (type === 1) {
      query = { isWithdraw: false, ...isPapara }
    }
    if (type === 2) {
      query = { isWithdraw: true, ...isPapara }
    }
    if (type === 3) {
      query = { isWithdraw: false, ...isPapara }
    }
    realm.accounts?.find({ ...query, bankerId: store.banker._id }).then((data: any) => {
      setAccounts(data)
    })
  }

  useEffect(() => {
    realm.accounts
      ?.find({ isWithdraw: true, ...isPapara, bankerId: store.banker._id })
      .then((data: any) => {
        setAccounts(data)
      })
  }, [])

  useEffect(() => {
    getAccounts()
  }, [type])

  let changeStatus = (id: string, active: boolean) => {
    realm.accounts
      ?.updateOne({ _id: id }, { $set: { isActive: !active, updatedAt: new Date() } })
      .then(() => {
        getAccounts()
      })
  }

  return (
    <>
      <Flex mb="4" justifyContent={'space-between'}>
        <HStack spacing={4}>
          {customData?.role === 'Havale' && (
            <>
              <Button
                colorScheme="green"
                variant="outline"
                isActive={type === 0}
                onClick={() => {
                  setType(0)
                }}
              >
                Çekim Hesapları
              </Button>
              <Button
                colorScheme="yellow"
                variant="outline"
                isActive={type === 1}
                onClick={() => {
                  setType(1)
                }}
              >
                Yatırım Hesapları
              </Button>
            </>
          )}
          {customData?.role === 'Papara' && (
            <>
              <Button
                colorScheme="red"
                variant="outline"
                isActive={type === 2}
                onClick={() => {
                  setType(2)
                }}
              >
                Papara Çekim Hesapları
              </Button>
              <Button
                colorScheme="purple"
                variant="outline"
                isActive={type === 3}
                onClick={() => {
                  setType(3)
                }}
              >
                Papara Yatırım Hesapları
              </Button>
            </>
          )}
        </HStack>
        <AddAccounts
          type={type}
          onClose={() => {
            getAccounts()
          }}
        />
      </Flex>

      <Flex bg="white" flexDir="column">
        <Grid
          gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr 1fr 100px 100px'}
          justifyItems="space-between"
          w={'100%'}
          px="5"
          py={2}
          alignItems={'center'}
        >
          <Text>Banka</Text>
          <Text>Hesap No</Text>
          <Text>Kişi</Text>
          <Text>IBAN Adresi</Text>
          <Text>Minimum </Text>
          <Text>Maksimum </Text>
          <Text textAlign={'center'}>Aktif / Pasif</Text>
          <Text textAlign={'center'}>Düzenle</Text>
        </Grid>
        <Divider />
        {accounts &&
          accounts?.map((item: any) => {
            return (
              <Grid
                gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr 1fr 120px 100px'}
                justifyItems="space-between"
                w={'100%'}
                p="5"
                alignItems={'center'}
              >
                <Text>{item.type}</Text>
                <Text fontWeight={'bold'}>{item.information.no}</Text>
                <Text>{item.information.name}</Text>
                <Text>{item.information.iban}</Text>
                <Text>{item.minAmount}</Text>
                <Text>{item.maxAmount}</Text>
                <Button
                  colorScheme={item.isActive ? 'green' : 'red'}
                  size="md"
                  mr={2}
                  onClick={() => {
                    changeStatus(item._id, item.isActive)
                  }}
                >
                  {item.isActive ? 'Aktif' : 'Pasif'} - Değiştir
                </Button>
                <EditAccounts
                  type={type}
                  id={item._id}
                  onClose={() => {
                    getAccounts()
                  }}
                />
              </Grid>
            )
          })}
      </Flex>
    </>
  )
}
