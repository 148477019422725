import { extendTheme } from '@chakra-ui/react'
import '@fontsource/inter'

const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        color: 'gray.600',
        backgroundColor: '#F4F5FA'
      }
    }
  },
  fonts: {
    body: `'Inter', sans-serif`
  },
  colors: {
    brand: {
      100: '#f7fafc',
      900: '#1a202c'
    },
    yellow: {
      '50': '#FFFBE5',
      '100': '#FFF4B8',
      '200': '#FFEE8A',
      '300': '#FFE75C',
      '400': '#F7DB4B',
      '500': '#FFD900',
      '600': '#CCAE00',
      '700': '#998200',
      '800': '#665700',
      '900': '#332B00'
    }
  }
})

export default theme
