import { AxiosRequestConfig, AxiosResponse } from 'axios'
import create from 'zustand'
import { baseInstance } from './axios'
import endpoints, { Endpoints } from './endpoints'

interface IFetchData extends AxiosRequestConfig {
  endpoint: Endpoints
}

interface FetchState {
  login?: AxiosResponse
  register?: AxiosResponse
  getRegister?: AxiosResponse
  getClient?: AxiosResponse
  sessions?: AxiosResponse
  forgotPassword?: AxiosResponse
  changePassword?: AxiosResponse
  fetch: (data: IFetchData) => void
}

export const useBaseFetch = create<FetchState>((set) => ({
  login: undefined,
  register: undefined,
  getRegister: undefined,
  getClient: undefined,
  sessions: undefined,
  forgotPassword: undefined,
  changePassword: undefined,
  fetch: async ({ endpoint, data, params, headers }: IFetchData) => {
    try {
      const response = await baseInstance.request({
        url: endpoints[endpoint].url,
        method: endpoints[endpoint].method,
        data: data,
        headers: headers,
        params: params
      })
      set({ [endpoint]: await response })
    } catch (error: any) {
      set({ [endpoint]: error.response })
    }
  }
}))
